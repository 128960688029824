import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_features.js";
import { Link } from 'gatsby';
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Link to="/features/login" mdxType="Link">
  To review an activity you have to be logged in.
    </Link>
    <br />
    <br />
    <Link to="/features/activitypage" mdxType="Link">Open an activity</Link> and click on "Write review".
    <br />
    <br />
    <p>{`Click the attributes you want to add to your review.`}</p>
    <Image src="features/reviewform.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <h4>{`Add a comment`}</h4>
    <p>{`You can also add a comment to your review.`}</p>
    <br />
    <p>{`Click on "Save" and it's done.`}</p>
    <br />
    <p>{`To see your review go to the `}<Link to="/features/showreviews" mdxType="Link">{`"Reviews" tab`}</Link>{` in the activity page.`}</p>
    <Image src="features/reviewcomment.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <Link className="float-left" to="/features/showreviews" mdxType="Link">
  ← Show reviews
    </Link>
    <Link className="float-right" to="/features/showconditions" mdxType="Link">
  Show conditions →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      